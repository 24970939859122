<script>
	import { onMount } from 'svelte';
	import ComponentPageHead from '$lib/components/ComponentPageHead.svelte';
	export let data;

	/**
	 * @type {typeof import('svelte').SvelteComponent}
	 */
	let Component; //TODO: NEED TO THINK ABOUT BEST APPROACH FOR THIS FOR SSR

	onMount(async () => {
		// TODO: refine this
		// Home page is stored in _home
		const module = await import('./_home/+page.svelte');
		Component = module.default;
	});
</script>

{#if data.metadata}
	<ComponentPageHead content={data.metadata} />
{/if}

{#if Component}
	<!-- Fallback Component is handled in the resolveCondition method -->
	<svelte:component this={Component} />
{/if}
