export const prerender = true;

const metadata = {
    title: 'ABCmouse: Educational Games, Books, Puzzles & Songs for Kids & Toddlers',
    link: [
        {
            rel: 'canonical',
            href: 'https://www.abcmouse.com/abc/'
        },
        {
            rel: 'alternate',
            href: 'https://www.abcmouse.com',
            hreflang: 'en'
        },
        {
            rel: 'alternate',
            href: 'https://www.abcmouse.com/es',
            hreflang: 'es'
        }
    ],
    metaTag: [
        {
            name: 'description',
            content: 'ABCmouse.com helps kids learn to read through phonics, and teaches lessons in math, social studies, art, music, and much more. For kids age 2 to Kindergarten.'
        },
        {
            property: 'og:type',
            content: 'website'
        },
        {
            property: 'og:title',
            content: 'ABCmouse.com'
        },
        {
            property: 'og:image',
            content: 'https://img.abcmouse.com/fb/ABCmouse.jpg'
        },
        {
            property: 'og:site_name',
            content: 'ABCmouse.com'
        },
        {
            property: 'og:description',
            content: 'ABCmouse.com helps kids learn to read through phonics, and teaches lessons in math, social studies, art, music, and much more! For kids ages 2 to 8.'
        },
        {
            property: 'og:url',
            content: 'https://www.abcmouse.com'
        }
    ]
};

/**
 * @returns {Promise<{
 *   metadata: {
 *     title: string,
 *     link: Array<{
 *       rel: string,
 *       href: string,
 *       hreflang?: string
 *     }>
 *   }
 * }>}
 */
export function load() {
    return {
        metadata
    };
}
